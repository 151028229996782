<template>
  <v-container fluid>
    <v-row v-show="this.id">
      <v-col cols="12" md="4">
        <v-card class="pa-2">
          <v-card-title>
            <h4>Information sur la séance plénière d’attribution du marché concerné</h4>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <b>Titre de la séance plénière d’attribution :</b>
            {{ this.$store.getters["Auth/get_selected_attribution"].SA.title }}
            <v-spacer></v-spacer>
            <b>Date/heure de la séance plénière d’attribution :</b>
            {{
              this.$store.getters["Auth/get_selected_attribution"].SA.sessionDate
              | moment("dddd, Do MMMM YYYY")
            }}
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <b>Intitulé de la commission d’ouverture :</b>
            {{ this.$store.getters["Auth/get_selected_attribution"].SO.firstCommission.title }}
            <v-spacer></v-spacer>
            <b>Intitulé de la commission d’évaluation :</b>
            {{ this.$store.getters["Auth/get_selected_attribution"].SE.com.title }}
            <v-spacer></v-spacer>
          </v-card-text>
        </v-card>
        <v-card class="mt-2" v-if="profile == 4 && !this.$store.getters['Auth/get_selected_attribution'].signature_state
          ">
          <v-card-title>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <h4 class="h4" v-bind="attrs" v-on="on">Signature du Procès-Verbal d’attribution</h4>
              </template>
              <span>Signature du Procès-Verbal d’attribution</span>
            </v-tooltip>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="undo" class="white--text" icon>
              <v-icon>
                mdi-backup-restore
              </v-icon>Reprendre la signature
            </v-btn>
          </v-card-title>
          <v-card-text>
            <VueSignaturePad class="mx-auto" style="border:1px solid red" height="120px" width="100%"
              :options="{ penColor: 'rgba(0,43,89)', minWidth: 0.2, maxWidth: 1.5 }" ref="signaturePad" />

            <div></div>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" @click="save" class="white--text" block>
              <v-icon class="mr-2" dark>mdi-draw</v-icon>VALIDER LA SIGNATURE
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-card class="mt-4" v-if="profile != 2">
          <v-card-title>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <h4 class="h6" v-bind="attrs" v-on="on">Liste/nombre/qualité des membres de la commission d’attribution
                </h4>
              </template>
              <span>Liste/nombre/qualité des membres de la commission d’attribution</span>
            </v-tooltip>
            <v-spacer></v-spacer>
            <v-chip class="primary">
              <!-- + this.$store.getters['Auth/get_selected_attribution'].users_commission_eval.length -->
              {{ this.$store.getters["Auth/get_selected_attribution"].users_commission_os.length }}
            </v-chip>
          </v-card-title>
          <v-card-text>
            <v-virtual-scroll :items="this.$store.getters['Auth/get_selected_attribution'].users_commission_os"
              :item-height="50" height="110">
              <template v-slot="{ item }">
                <v-list-item>
                  <v-list-item-avatar>
                    <!-- <v-icon large color="primary" v-show="JSON.parse(localStorage.getItem('userData')).id == item.id">mdi-account-circle</v-icon> -->
                    <v-icon medium>mdi-account-circle</v-icon>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title>{{ item.user.firstname }}</v-list-item-title>
                    <v-list-item-subtitle>{{ item.user.lastname }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <span>{{ item.type.description }}</span>
                  </v-list-item-action>
                </v-list-item>
              </template>
            </v-virtual-scroll>
          </v-card-text>
          <v-card-text> </v-card-text>
        </v-card>

        <!-- <v-card class="mt-1" v-if="profile != 2">
          <v-card-text>
            <v-virtual-scroll
              :items="this.$store.getters['Auth/get_selected_attribution'].users_commission_eval"
              :item-height="50"
              height="110"
            >
              <template v-slot="{ item }">
                <v-list-item>
                  <v-list-item-avatar>
                    <v-icon medium>mdi-account-circle</v-icon>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title>{{ item.user.firstname }}</v-list-item-title>
                    <v-list-item-subtitle>{{ item.user.lastname }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <span>Membre</span>
                  </v-list-item-action>
                </v-list-item>
              </template>
            </v-virtual-scroll>
          </v-card-text>
          <v-card-text>
          </v-card-text>
        </v-card> -->
      </v-col>
      <v-col cols="12" md="8" v-if="profile != 2">
        <v-card>
          <v-card-title>
            <h4>
              Rédaction du Procès-Verbal (PV) de la séance plénière d’attribution du marché
              <!--{{ this.$store.getters["Auth/get_selected_attribution"].SA.sessionType.description }}-->
            </h4>
            <v-spacer></v-spacer>
            <span v-show="!this.$store.getters['Auth/get_selected_attribution'].SA.generatedSign">
              <v-btn v-show="this.$store.getters['Auth/get_selected_attribution'].pv" @click.prevent="GenerateSignature()"
                text depressed class="secondary">
                Generer la liste de presence
              </v-btn>
            </span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text v-if="!this.$store.getters['Auth/get_selected_attribution'].pv">
            <DynamicAttribution :key="this.id" :attributionID="this.id"
              :sessionPV="this.$store.getters['Auth/get_selected_attribution'].pv_eval.file_blob"
              :sessionType="this.$store.getters['Auth/get_selected_attribution'].attribution_type" />
          </v-card-text>
          <v-card-text v-if="this.$store.getters['Auth/get_selected_attribution'].pv">
            <EditorViewer v-if="profile == 3" :isAllowedPrint="true" :key="this.id"
              :isPvObject="this.$store.getters['Auth/get_selected_attribution'].pv" />
            <EditorViewer v-if="profile != 3" :isAllowedPrint="false" :key="this.id"
              :isPvObject="this.$store.getters['Auth/get_selected_attribution'].pv" />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="8" v-if="profile == 2">
        <v-card>
          <v-card-title>
            <h4>
              Le Procès-Verbal (PV) de la
              {{ this.$store.getters["Auth/get_selected_attribution"].SA.sessionType.description }}
            </h4>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text v-if="this.$store.getters['Auth/get_selected_attribution'].pv">
            <EditorViewer :isAllowedPrint="false" :key="this.id"
              :isPvObject="this.$store.getters['Auth/get_selected_attribution'].pv" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import DatePicker from "vue2-datepicker";
import DynamicAttribution from "../../../config/DynamicAttribution";
import EditorViewer from "../../../config/EditorViewer";
import "vue2-datepicker/index.css";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { EditorViewer, DynamicAttribution, DatePicker },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    userData: "",
    fin: {
      start_time: null,
      selected: [],
    },
    allSign: [],
    GeneratedSignListCom: "",
    com: {
      file: [],
      com: 0,
    },
    docorNoticeID: 0,
    pv: [],
    ppm: [],
    additif: false,
    addi: {
      objet: "",
      publi_date: new Date(),
      file: [],
    },
    letter: {
      type: 0,
      file: [],
      users: [],
    },
    allUserstoDoc: [],
    attribution: {
      title: "",
      start_time: null,
      document: 0,
      user: [],
      commission: ""
    },
  }),
  computed: {
    ...mapGetters({
      get_eval_signature: "Auth/get_all_signature",
      get_sync_so: "Auth/get_sync_so"
    }),
  },
  created() {
    let Virt = [];
    Virt = JSON.parse(localStorage.getItem("userData"));
    this.profile = Virt.profile.status;
    this.userData = Virt;
    this.$store.dispatch("Auth/getAttribution", this.id);
    this.$store.dispatch("Auth/getSession", this.id);
    this.$store.dispatch("Auth/getOfferKeys", this.id);
  },
  methods: {
    ...mapActions({
      loadAllSignature: "Auth/loadAllSignature"
    }),
    undo() {
      this.$refs.signaturePad.clearSignature();
    },
    async UpdatePvSession(contentReceived, signature) {
      const data = new FormData();
      let content = contentReceived;
      content += signature;
      data.append("file_blob", content);
      data.append("attribution_id", this.id);
      await this.$store.dispatch("Auth/addPV", data);
      this.$router.go();
    },
    async GenerateSignature() {
      let signat = [];
      await this.loadAllSignature(this.id).then(() => {
        // eslint-disable-next-line prettier/prettier
        new Promise((r) => setTimeout(r, 2000));
        signat = this.$store.getters["Auth/get_all_signature"];
      });
      this.allSign = signat.data;
      // console.log(this.allSign);
      this.getThem();
    },
    async getThem() {
      let contener = "";
      const session = this.id;
      const collected_signature = this.allSign.filter(
        (value, index, arr) => value.openSession.id == session,
        session,
      );
      const AllCom = collected_signature.filter((value, index, arr) => value.com, session);

      AllCom.forEach((element, index, arr) => {
        this.GeneratedSignListCom += `<tr>
            <td>${index + 1}</td>
            <td>${element.name} ${element.surname}</td>
            <td>${element.fonction}</td>
            <td>${element.quality}</td>
            <td>
              <img width="277" src="${element.fileBlob}" alt="Signature ${element.id}" />
            </td>
          </tr>`;
      });

      const signatureCom = `<p>
      <strong>Annexe 1 : Liste de présence des membres de la comission</strong></p>
      <div class="tableWrapper">
            <table style="min-width: 50px;">
              <tbody>
                <tr>
                  <th><p>N°</p></th>
                  <th><p>Nom et prénoms</p></th>
                  <th><p>Fonction/Structure</p></th>
                  <th><p> Qualité du membre de la commission</p></th>
                  <th><p>Signature</p></th>
                </tr>
                ${this.GeneratedSignListCom}
              </tbody>
            </table>
        </div>
        <p><br class="ProseMirror-trailingBreak"></p>`;

      contener += signatureCom;
      console.log(contener);

      this.UpdatePvSession(
        this.$store.getters["Auth/get_selected_attribution"].pv.file_blob,
        contener,
      );
    },
    save() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      const dataSign = new FormData();
      const userEmail = this.userData.email;
      const ChartTitle = this.$store.getters["Auth/get_selected_attribution"].users_commission_os;
      const UserTitle = ChartTitle.filter(
        (value, index, arr) => value.user.email == userEmail,
        userEmail,
      );
      dataSign.append("file_blob", data);
      dataSign.append("attribution_id", this.id);
      // PROFILE SIGNING
      dataSign.append("is_rep", 0);
      dataSign.append("is_op", 0);
      dataSign.append("is_com", 1);
      // DATA TO SEND
      dataSign.append("name", this.userData.firstname);
      dataSign.append("surname", this.userData.lastname);
      dataSign.append("email", this.userData.email);
      dataSign.append("fonction", this.userData.profession);
      if (UserTitle[0]) {
        dataSign.append("quality", UserTitle[0].type.description);
      } else {
        dataSign.append("quality", "Membre");
      }

      if (!isEmpty) {
        // console.log(UserTitle[0].type.description);
        this.$store.dispatch("Auth/addAttrSignature", dataSign);
      } else {
        // alert swal sign empty
      }
    },
    financialProp() {
      const data = new FormData();
      data.append("document", this.com.com);
      data.append("selected_offer", this.fin.selected);
      data.append("start_time", this.fin.start_time);
      data.append("duration", 160);
      data.append("password", 1234);
      this.$store.dispatch("Auth/comCall", data);
    }
  },
};
</script>
<style>
.w-62em {
  height: 62em !important;
  width: 100% !important;
}

.v-card__text {
  width: 100% !important;
}

.vue-friendly-iframe iframe {
  width: 100% !important;
  height: 62em !important;
}

.vue-daterange-picker {
  width: 100%;
}

.mx-datepicker {
  width: 100%;
}
</style>
