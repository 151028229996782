<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="6" v-if="profile == 3">
        <v-card class="mt-4">
          <v-card-title>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <span class="h6" v-bind="attrs" v-on="on"
                  ><h4>Ajout d’une séance plénière d’attribution du marché</h4></span
                >
              </template>
              <span>Ajout d’une séance plénière d’attribution du marché</span>
            </v-tooltip>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <!--             <v-switch
              v-model="Dtype"
              :label="`Je créer une attribution pour une réponse : ${!Dtype ? 'Non' : 'Oui'}`"
            ></v-switch> -->
            <v-text-field
              v-model="attribution.title"
              name="input-7"
              label="Saisir le titre de la séance plénière d’attribution du marché"
            ></v-text-field>
            <v-autocomplete
              v-if="!Dtype"
              v-model="attribution.document"
              :items="this.$store.getters['Auth/getlistSubmissionFoldNoti'].docs"
              item-text="object"
              item-value="id"
              outlined
              label="Sélectionner le dossier correspondant à la séance plénière d’attribution du marché"
            ></v-autocomplete>
            <v-autocomplete
              v-if="Dtype"
              v-model="attribution.document"
              :items="this.$store.getters['Auth/getlistSubmissionFoldNoti'].notice_ami_apq"
              item-text="object"
              item-value="id"
              outlined
              label="Choisir l'avis "
            ></v-autocomplete>
            <date-picker
              label="Renseigner la date/l’heure de la séance plénière d’attribution du marché"
              v-model="attribution.start_time"
              value-type="format"
              class="mb-4"
              format="YYYY-MM-DD H:m:s"
              :default-value="new Date()"
              type="datetime"
              placeholder="Renseigner la date/l’heure de la séance plénière d’attribution du marché"
            ></date-picker>

            <v-btn color="primary" small @click="addAttribution" class="white--text" block>
              <v-icon small dark>mdi-ticket</v-icon>PROGRAMMER LA SEANCE PLENIERE D’ATTRIBUTION
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" :md="dynamicCol">
        <v-col>
          <v-card class="mx-auto">
            <v-card-title>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <span class="h6" v-bind="attrs" v-on="on"
                    ><h4>
                      Liste détaillée des séances plénières d’attributions des marchés
                    </h4></span
                  >
                </template>
                <span> Liste détaillée des séances plénières d’attributions des marchés </span>
              </v-tooltip>
              <v-spacer></v-spacer
              ><v-chip class="ma-2">{{
                this.$store.getters["Auth/get_all_attribution"].length
              }}</v-chip>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-virtual-scroll
                :items="this.$store.getters['Auth/get_all_attribution']"
                :item-height="50"
                height="400"
              >
                <template v-slot="{ item }">
                  <v-list-item>
                    <v-list-item-avatar>
                      <v-icon large>mdi-ticket</v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title>{{ item.title }}</v-list-item-title>
                      <v-list-item-subtitle>{{
                        item.createdAt | moment("YYYY-MM-DD à hh:mm:ss")
                      }}</v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-btn @click="seemeeting(item)" depressed small>
                        voir
                        <v-icon color="orange darken-4" right>mdi-eye</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </template>
              </v-virtual-scroll>
            </v-card-text>
          </v-card>
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { mapActions } from "vuex";

export default {
  components: {
    DatePicker
  },
  data: () => ({
    attribution: {
      title: "",
      start_time: null,
      document: 0
    },
    dynamicCol: 6,
    Dtype: false
  }),
  created() {
    this.$store.dispatch("Auth/getAllConfigHome");
    this.$store.dispatch("Auth/getSubmissionFoldNoti");
    let Virt = [];

    Virt = JSON.parse(localStorage.getItem("userData"));
    this.profile = Virt.profile.status;
    if (this.profile == 4) {
      this.dynamicCol = 12;
    }
    this.user_data = Virt;
    this.$store.dispatch("Auth/loadAllDoc");
    this.$store.dispatch("Auth/loadAllAttribution");
    this.$store.dispatch("Auth/loadAllCom");
  },
  methods: {
    ...mapActions({
      extension_addN: "Auth/extension_addN"
    }),
    addAttribution() {
      const data = new FormData();
      if (this.attribution.title) {
        data.append("title", this.attribution.title);
        if (this.Dtype) {
          data.append("notice", this.attribution.document);
        } else {
          data.append("document", this.attribution.document);
        }
        data.append("date", this.attribution.start_time);
        this.$store.dispatch("Auth/addAttribution", data);
      }
    },
    seemeeting(item) {
      console.log(item);
      this.$router.push({ name: "showAttribution", params: { id: item.id } });
    }
  }
};
</script>
<style>
.vue-daterange-picker {
  width: 100%;
}
.mx-datepicker {
  width: 100%;
}
.w-62em {
  height: 62em !important;
  width: 100% !important;
}
.v-card__text {
  width: 100% !important;
}
.vue-friendly-iframe iframe {
  width: 100% !important;
  height: 62em !important;
}
</style>
